
import { useState, useEffect } from "react";
import Header from "../Header";
import { format_date, digitGroup, userPrivTargets, liste_priv, MySpinner, slang } from "../Utils";
import { useTranslation } from "react-i18next";
import { UserAccessExpired } from "../AccesDenied";

const proxyUrl = require("../../../package.json").proxy;

const TicketComponent = function ({ idData, content: ct, dateHeure }) {

    const { t } = useTranslation()

    if (typeof (ct) === 'string') ct = JSON.parse(ct) // apparemment en local avec mysql workbench ça ressort bien en objet, mais en ligne avec phpmyadmin ça sort en string    

    return <div>

        <div className="card m-3 p-0 mx-auto">

            <div className="card-header">
                <h6 className="display-6 card_title">{"Ticket " + ct[0]["TICKET.IdTicket"] + " (" + idData + ") - Total " + digitGroup(ct[0]["Total"])}</h6>
                <p className="p-0 m-0">{format_date(Date.parse(ct[0]["DateCreation"]?.toString()))} - {"Serveur " + ct[0]["IdServeur"]} - {"Tarification " + ct[0]["IdTarification"]}</p>
                <p><small>Request time: {format_date(Date.parse(dateHeure))}</small></p>
            </div>
            <div className="card-body">

                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">{t("pending.table_qte")}</th>
                            <th scope="col">{t("pending.table_libelle")}</th>
                            <th className="text-end" scope="col">{t("pending.table_pu")}</th>
                            <th className="text-end" scope="col">{t("pending.table_remise")}</th>
                            <th className="text-end" scope="col">{t("pending.table_pt")}</th>
                        </tr>
                    </thead>
                    <tbody>

                        {ct.map((row, i) => {

                            function if_ligne_a_suivre(elt) {
                                if (row.TypeTicketLigne === 1) return ""
                                return elt
                            }

                            return <tr key={i}>
                                <td>{if_ligne_a_suivre(row.Qte)}</td>
                                <td>{row.Libelle}</td>
                                <td className="text-end">{if_ligne_a_suivre(digitGroup(row.PU))}</td>
                                <td className="text-end">{if_ligne_a_suivre(digitGroup(row.PU * row.Qte - row.PT))}</td>
                                <td className="text-end">{if_ligne_a_suivre(digitGroup(row.PT))}</td>
                            </tr>
                        })}
                    </tbody>
                </table>

            </div>

        </div>
    </div>
}

const PendingPage = function (props) {

    const { t } = useTranslation()

    const [validateItem, setValidateItem] = useState(false);

    const [item, setItem] = useState({});

    const [showSpinner1,
        setShowSpinner1] = useState(true);
    const [showSpinner2,
        setShowSpinner2] = useState(true);

    const [remises_en_attente,
        setRemises_en_attente] = useState([]);

    const [suppr_tk_en_attente,
        setSuppr_tk_en_attente] = useState([]);


    // fetch Demandes de Remises
    useEffect(() => {

        const fetchRemises = async () => {
            try {

                setShowSpinner1(true)

                const response = await fetch(proxyUrl + "/demandes", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                    body: JSON.stringify({
                        "data_array": userPrivTargets(props.user, liste_priv.data.remise),
                        "typeElement": 3, // 3 remise, 5 suppr tk, 7 transfert
                    }),

                })
                const data = await response.json();

                setRemises_en_attente(data);

                setShowSpinner1(false)

            } catch (error) {
                console.error(error);
            }
        };

        if (props.user?.email !== "") fetchRemises();

    }, [props.user])

    const handleRemise = function (remise) {

        // on doit valider la remise en question (item)
        setItem(remise)
        setValidateItem(true)
    }

    const acceptRemise = () => {

        (async () => {
            try {
                const response = await fetch(proxyUrl + '/accept_demande', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    credentials: "include",
                    body: JSON.stringify({
                        idData: item.idData,
                        typeElementDemande: 3, // 3. dde Remise  5. dde Suppr tkt  7. dde Transfert, les annulations se font en local.js puis par cancel_ask qui supprime 1 cible
                        typeElementPending: 2, // 1. Reset pwd  2. Remise accordée  3. Suppr tk accordée  4. Transfert validé  5. maj bigCode Pdt  6. maj bigCode Pers
                        idLigne: item.idLigne,
                        bigCode: 0,
                        idUser: props.user?.id,
                    })

                });
                await response.json();

                window.location.reload();

            } catch (error) {
                console.error(error);
            }
        })();

        setValidateItem(false)
    }

    // fetch Demandes de Suppr Tk
    useEffect(() => {

        const fetchSupprTk = async () => {

            setShowSpinner2(true)

            try {
                const response = await fetch(proxyUrl + "/demandes", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                    body: JSON.stringify({
                        "data_array": userPrivTargets(props.user, liste_priv.data.suppr),
                        "typeElement": 5, // 3 remise, 5 suppr tk, 7 transfert
                    }),

                })
                const data = await response.json();

                setSuppr_tk_en_attente(data);

                setShowSpinner2(false)

            } catch (error) {
                console.error(error);
            }
        };

        if (props.user?.email !== "") fetchSupprTk();

    }, [props.user])

    const handleSupprTk = function (supprTk) {

        // on doit valider la remise en question (item)
        setItem(supprTk)
        setValidateItem(true)
    }

    const acceptSupprTk = () => {

        (async () => {
            try {
                const response = await fetch(proxyUrl + '/accept_demande', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    credentials: "include",
                    body: JSON.stringify({
                        idData: item.idData,
                        typeElementDemande: 5, // 3. dde Remise  5. dde Suppr tkt  7. dde Transfert, les annulations se font en local.js puis par cancel_ask qui supprime 1 cible
                        typeElementPending: 3, // 1. Reset pwd  2. Remise accordée  3. Suppr tk accordée  4. Transfert validé  5. maj bigCode Pdt  6. maj bigCode Pers
                        idLigne: item.idLigne,
                        bigCode: 0,
                        idUser: props.user?.id,
                    })

                });
                await response.json();

                window.location.reload();

            } catch (error) {
                console.error(error);
            }
        })();

        setValidateItem(false)
    }

    return <div>
        <Header user={props.user} />

        {((new Date(props.user.dateFin)) < Date.now()) ?
            <UserAccessExpired user={props.user} />
            :
            <>


                <div className="col mx-auto" style={{ maxWidth: "1200px" }}>

                    <MySpinner show={showSpinner1} myText={t("general.loading") + slang(" Demandes de remise", " Discount requests")} />

                    {(showSpinner1 === false) &&

                        <div className="row m-3">

                            <h1>{t("pending.title")}</h1>

                            <h3 className="display-6" style={{ color: "green" }}>{t("pending.titre_remise")}(x{remises_en_attente.length})</h3>

                            {remises_en_attente.length === 0 && <p className="display-6 text-secondary">{t("pending.no_remise")}</p>}

                            {remises_en_attente.map((remise, idx) => {

                                return <div className="mt-2 row" key={(idx)}>

                                    <div className="col-8">
                                        <TicketComponent idData={remise.idData} content={remise.content} dateHeure={remise.dateHeure} />
                                    </div>

                                    <div className="col-4 py-3">
                                        <button className="btn btn-lg fs-4 btn-outline-success" onClick={() => handleRemise(remise)}>{t("pending.btn_remise")}</button>

                                        {(validateItem && (item.idData === remise.idData && (item.idLigne === remise.idLigne) && (item.typeElement === remise.typeElement))) &&
                                            <>
                                                <button className="btn btn-lg fs-4 btn-warning" type="button" onClick={acceptRemise}>{t("general.confirm")}</button>
                                                <button className="btn btn-lg fs-4 btn-outline-danger" type="button" onClick={() => setItem({})}>{t("general.cancel")}</button>
                                            </>
                                        }
                                    </div>
                                </div>
                            })}
                        </div>
                    }

                    <MySpinner show={showSpinner2} myText={t("general.loading") + slang(" Demandes de suppression de tickets", " Deletion requests")} />

                    {(showSpinner2 === false) &&

                        <div className="row m-3">

                            <h3 className="display-6" style={{ color: "brown" }}>{t("pending.titre_suppr")}(x{suppr_tk_en_attente.length})</h3>

                            {suppr_tk_en_attente.length === 0 && <p className="display-6 text-secondary">{t("pending.no_suppr")}</p>}

                            {suppr_tk_en_attente.map((supprTk, idx) => {

                                return <div className="mt-2 row" key={(idx)}>

                                    <div className="col-8">

                                        <TicketComponent idData={supprTk.idData} content={supprTk.content} dateHeure={supprTk.dateHeure} />

                                    </div>

                                    <div className="col-4 py-3">

                                        <button className="btn btn-lg fs-4 btn-outline-danger" onClick={() => handleSupprTk(supprTk)}>{t("pending.btn_suppr")}</button>
                                        {(validateItem && (item.idData === supprTk.idData && (item.idLigne === supprTk.idLigne) && (item.typeElement === supprTk.typeElement))) &&
                                            <>
                                                <button className="btn btn-lg fs-4 btn-danger" type="button" onClick={acceptSupprTk}>{t("general.confirm")}</button>
                                                <button className="btn btn-lg fs-4 btn-outline-danger" type="button" onClick={() => setItem({})}>{t("general.cancel")}</button>
                                            </>
                                        }
                                    </div>
                                </div>
                            })}
                        </div>
                    }
                </div>

            </>
        }

    </div>
}

export default PendingPage;