
import InterventionForm from "./InterventionForm";
import AdminPage from "../AdminPage";
import { format_date, format_date_nohour, kliste_types_intervention, slang } from "../Utils";
import Header from "../Header";


const InterventionAdmin = function (props) {

    const table_titles = [
        "id",
        "type - date",
        slang("contenu","content"),
        "details",
        "options"
    ];

    return <div>

        <Header user={props.user} />

        <div className="col-lg-9 mx-auto">

            <AdminPage
                title={slang("Les Interventions", "Interventions")}
                endpoint="intervention"
                CustomForm={InterventionForm}
                table_titles={table_titles}
                filt1="idDossierClient"
                filt2="content"
                filt3="idData"
                itemsPerPage={5}
                RowContent={({ elt }) => {
                    return <>
                        <th scope="row">{elt.id}</th>
                        <td>
                            {kliste_types_intervention[elt.typeIntervention][1]}
                            <p>{format_date_nohour(elt.dateHeure)}</p>
                        </td>
                        <td>{elt.content}</td>
                        <td className="fs-4">
                            <p className="m-0">Modif.{format_date(elt.dateModification)}</p>
                            <p className="m-0">Creation.{format_date(elt.dateCreation)}</p>
                            <p className="m-0">data {elt.idData + " " + elt.denomination}</p>
                            <p className="m-0">doss {elt.idDossierClient + " " + elt.nomClient}</p>
                            <p className="text-primary">{elt.username + " (" + elt.idUser + ")"}</p>
                        </td>
                    </>
                }}
            />

        </div>
    </div>
}

export default InterventionAdmin;