
import { useCallback, useEffect, useState } from "react";
import Header from "../Header";
import { Line, Pie } from "react-chartjs-2";
import ActivityFilter, { modeStat } from "./ActivityFilter";

// eslint-disable-next-line
import Chart from 'chart.js/auto'; // cette ligne permet d'enregistrer directement les composants utilisés ex: LineElement
import { MyScrollButton, MySpinner, NbItems, digitGroup, format_date, jump, liste_priv, slang, userHasPriv } from "../Utils";
import PaginatedItems from "../Paginator";
import { MyInputSearch } from "../FormElements";
import { UserAccessExpired } from "../AccesDenied";
import { useTranslation } from "react-i18next";
import { ResponsiveTable } from "../ResponsiveTable";

Chart.defaults.font.size = 24;

const proxyUrl = require("../../../package.json").proxy;

const ActivityPage = function (props) {

    const { t } = useTranslation()

    const [showFilter, setShowFilter] = useState(true)
    const [showSpinner, setShowSpinner] = useState(false)

    const [modeActuel, setModeActuel] = useState(0) // 1:ventes 2:benefice 3:tickets
    const [groupeDates, setGroupeDates] = useState(2) // 1:jour 2:semaines 3:années
    const [categorie, setCategory] = useState(1) // 1:globales 2:groupées par famille 3:groupées par activités
    const [typeGraph, setTypeGraph] = useState(1) // 1:courbe 2:camembert

    const [data1, setData1] = useState(null)
    const [data2, setData2] = useState(null)
    const [data_labels1, setData_labels1] = useState([])
    const [data_labels2, setData_labels2] = useState([]) // créé uniquement pour le tableau associé au graphe
    const [data_labels3, setData_labels3] = useState([])
    const [datasets1, setDatasets1] = useState([]) // for line chart (simple or with benef)
    const [datasets2, setDatasets2] = useState([]) // for line chart (fam or act)
    const [datasets3, setDatasets3] = useState([]) // for pie chart

    const [dataTable1, setDataTable1] = useState([]) // result_table1
    const [dataTable2, setDataTable2] = useState([]) // result_table2
    const [dataTable3, setDataTable3] = useState([]) // result_table3

    const [tickets, setTickets] = useState([])
    const [ticketsFiltered, setTicketsFiltered] = useState([])
    const [tickets_suppr, setTickets_suppr] = useState([])
    const [tickets_supprFiltered, setTickets_supprFiltered] = useState([])

    const tomorrow = (new Date(Date.now() + 86400000))

    const [current_selection, setCurrent_Selection] = useState({
        idDossierClient: 0,
        dateDebut: (new Date()).toISOString().split('T')[0],
        dateFin: tomorrow.toISOString().split('T')[0],
        idData: [],
    })

    const handleChoice = useCallback(function (choice) {

        if (modeActuel === choice) return


        if (modeActuel === modeStat.tickets) {

            if (choice !== modeStat.tickets) {

                setShowFilter(true)
            }
        } else {
            if (choice === modeStat.tickets) {
                setShowFilter(true)
            }
        }

        setModeActuel(choice);
    }, [modeActuel])


    // Charge les données, fonction appelée depuis ActivityFilter

    const fetchDataFromServer = useCallback(async function (justTicket) {

        const getData = async (url) => {

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    credentials: "include",
                    body: JSON.stringify({ ...current_selection })
                });

                return response.json()
            } catch (error) {
                console.error(error);
            }
        }

        setShowSpinner(true)

        if (justTicket) {

            const tk_data = await getData(proxyUrl + "/tickets");

            setTickets(tk_data);
            setTicketsFiltered(tk_data);

            const tk_data_suppr = await getData(proxyUrl + "/tickets_suppr");

            setTickets_suppr(tk_data_suppr);
            setTickets_supprFiltered(tk_data_suppr);

        } else {

            setData1(await getData(proxyUrl + "/ventes"));
            setData2(await getData(proxyUrl + "/ventes_fam_act"));
        }


        setShowSpinner(false)

    }, [current_selection])


    // Charge les données de VENTES GLOBALES pour le graphique

    useEffect(() => {

        let my_data_labels = []
        let my_data_values = []
        let my_data_pa = []
        let my_data_benef = []
        let my_datasets = []

        if (!data1) return

        let dataSource = data1

        for (const [key, value] of Object.entries([dataSource.jours, dataSource.semaines, dataSource.mois][groupeDates - 1])) {

            my_data_labels.push(key)
            my_data_values.push(value[0])
            my_data_pa.push(Math.abs(value[1]))
            my_data_benef.push(value[0] + value[1])
        }

        switch (modeActuel) {
            case modeStat.ventes:
                my_datasets = [{
                    label: slang('Ventes', 'Sales'),
                    data: my_data_values,
                    borderWidth: 2,
                    borderColor: 'rgb(142, 163, 200)',
                }]
                break;
            case modeStat.benef:
                my_datasets = [{
                    label: slang('Ventes', 'Sales'),
                    data: my_data_values,
                    borderWidth: 2,
                    borderColor: 'rgb(142, 163, 200)',
                }, {
                    label: slang('Cout de revient', 'Cost price'),
                    data: my_data_pa,
                    borderWidth: 2,
                    borderColor: 'rgb(250, 167, 165)',
                }, {
                    label: slang('Bénéfice', 'Balance'),
                    data: my_data_benef,
                    borderWidth: 2,
                    borderColor: 'rgb(167, 230, 165)',
                }]
                break;

            default:
                break;
        }

        setData_labels1(my_data_labels)
        setDatasets1(my_datasets)

        let newArray = []

        switch (modeActuel) {
            case modeStat.ventes:

                newArray = my_data_labels.map((l, idx) => {
                    return { label: l, value: my_datasets[0].data[idx] }
                });
                setDataTable1(newArray)
                break;

            case modeStat.benef:
                newArray = my_data_labels.map((l, idx) => {
                    let result = []

                    result.push(l);
                    result.push(my_data_values[idx]);
                    result.push(my_data_pa[idx]);
                    result.push(my_data_benef[idx]);

                    return result
                });
                setDataTable1(newArray)
                break;

            default:
                break;
        }



    }, [data1, modeActuel, categorie, groupeDates])

    // Charge les données de VENTES FAMILLES / ACTIVITES pour le graphique

    useEffect(() => {

        function sortObject(obj) {
            return Object.keys(obj).sort().reduce(function (result, key) {
                result[key] = obj[key];
                return result;
            }, {});
        }

        let my_graph_labels = []
        let my_data_labels = []
        let my_data_values = []
        let my_datasets = []
        let my_data_labels3 = []
        let my_data_values3 = []
        let my_datasets3 = []
        let dataSource = []

        if (!data2) return

        if (categorie === 2) {

            dataSource = data2["fam"]
        } else {

            dataSource = data2["act"]
        }


        for (let i = 0; i < Object.keys(dataSource).length; i++) {
            const graph_key = Object.keys(dataSource)[i]
            const element = dataSource[graph_key];

            let total_element = 0

            my_data_labels[graph_key] = []
            my_data_values[graph_key] = []


            for (const [key, value] of Object.entries([sortObject(element.jours), sortObject(element.semaines), sortObject(element.mois)][groupeDates - 1])) {

                my_graph_labels.push(key)
                my_data_labels[graph_key].push(key)
                my_data_values[graph_key].push({ x: key, y: value })
                total_element += value
            }

            my_data_labels3.push(element.nomSerie)
            my_data_values3.push(total_element)

            my_datasets.push({
                label: element.nomSerie,
                data: my_data_values[graph_key],
                borderWidth: 2,
            })

        }


        const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);

        const randomRGB = () => `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;

        my_datasets3.push({
            label: 'Sales',
            data: my_data_values3,
            backgroundColor: my_data_labels3.map(l => {

                return randomRGB()
            }),

            borderWidth: 1,
        })

        let newArrayLabels = []

        // datasets2 (pour les ventes act et grpes_fam)
        setDatasets2(my_datasets)

        newArrayLabels = my_datasets.map((d, idx) => {
            return d.label
        });
        setData_labels2([categorie === 2 ? slang("Groupes Familles", "Families Groups") : slang("Activités", "Activities"), ...newArrayLabels, "Total"])

        let newArrayDataTable = []

        if (my_datasets.length > 0) {

            newArrayDataTable = my_datasets[0]["data"].map((c, idx) => {

                let interm_result = [];

                for (let i = 0; i < newArrayLabels.length + 2; i++) {

                    let d = []

                    if (i > newArrayLabels.length) {

                        interm_result.push(interm_result.slice(1).reduce((acc, b) => acc + b, 0))
                    } else {

                        d = my_datasets[(i - 1 === -1) ? 0 : i - 1]["data"]

                        if (i === 0) {

                            interm_result.push(d[idx].x)

                        } else {

                            interm_result.push(d[idx].y)
                        }
                    }
                }

                return interm_result
            });
        }


        setDataTable2(newArrayDataTable)





        // datasets3 (pour le graphique pie Chart)
        setData_labels3(my_data_labels3)
        setDatasets3(my_datasets3)

        let newArray = []

        newArray = my_data_labels3.map((l, idx) => {
            return { label: l, value: my_datasets3[0].data[idx] }
        });
        setDataTable3(newArray)

    }, [data2, modeActuel, categorie, groupeDates])


    // Liste des Tickets
    const ListeTickets = function ({ settled_or_deleted, id_display }) {

        const [currentItems, setCurrentItems] = useState([]);
        let baseItems = []

        const TicketRows = function ({ content: [tk, ct] }) {

            if (!tk) return <></>

            const get_special_color = function () {
                return settled_or_deleted ? "text-primary" : "text-danger"
            }

            return <div className="row">

                <div className="card m-3 p-0 col-lg-10 col-12 mx-auto">

                    <div className="card-header">
                        <p className="card_title display-6">
                            Ticket <span className={get_special_color()}>
                                {tk["IdTicket"]}
                            </span>

                            {" - Total " + tk["Total"]}

                            <span className={get_special_color()}>
                                {settled_or_deleted ? slang(" - Payé", " - Settled") : slang(" - Supprimé", " - Deleted")}
                            </span>
                        </p>
                        <p>
                            {format_date(Date.parse(tk.DateCreation?.toString())) + " - "}
                            <span>{(tk["IdServeur"] ? "Serveur " + tk["IdServeur"] + " - " : '')}</span>
                            <span className="text-primary">{(tk["nomClient"].length > 0 ? "Client " + tk["nomClient"] + " - " : '')}</span>
                            {"Tarification " + tk["IdTarification"]}
                        </p>
                    </div>
                    <div className="card-body">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Qte</th>
                                    <th scope="col">Libelle</th>
                                    <th className="text-end" scope="col">PU</th>
                                    <th className="text-end" scope="col">Remise</th>
                                    <th className="text-end" scope="col">PT</th>
                                </tr>
                            </thead>
                            <tbody>

                                {ct.map((row, i) => {

                                    const if_ligne_a_suivre_or_compo_pack = (elt) => {
                                        if (row.TypeTicketLigne === 1 || (row.TypeTicketLigne === 11 || row.TypeTicketLigne === 12)) return ""
                                        return elt
                                    }


                                    return <tr key={i}>
                                        <td>{if_ligne_a_suivre_or_compo_pack(row.Qte)}</td>
                                        <td>{row.Libelle}
                                            {row.Commentaire ? <p>{row.Commentaire}</p> : ""}
                                        </td>
                                        <td className="text-end text-nowrap">{if_ligne_a_suivre_or_compo_pack(digitGroup(row.PU))}</td>
                                        <td className="text-end text-nowrap">{if_ligne_a_suivre_or_compo_pack(digitGroup((-1 * row.PU * row.Qte) + row.PT))}</td>
                                        <td className="text-end text-nowrap">{if_ligne_a_suivre_or_compo_pack(digitGroup(row.PT))}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>

                        <div className="card-footer text-end">

                            <button className="btn btn-lg btn-outline-success fs-3" onClick={() => {

                                setTkRows([])

                            }}>Close</button>
                        </div>
                    </div>

                </div>
            </div >
        }

        const [tk_rows, setTkRows] = useState([])

        const display_Ticket = async function (idTicket, idData) {

            let bool_tkfound = false

            const fetchTkRows = async () => {

                const url = proxyUrl + '/ticket' + (settled_or_deleted ? "/" : "_suppr/") + idTicket + '/' + idData

                try {

                    const response = await fetch(url);
                    if (response.status === 200) {

                        const data = await response.json();
                        setTkRows(data)
                        bool_tkfound = true
                    }

                } catch (error) {
                    console.error(error);
                }
            };

            await fetchTkRows();

            if (bool_tkfound) jump(id_display)
        }

        const ListeOptions = function ({ id, idData }) {

            return <td>
                <button className="btn" onClick={() => display_Ticket(id, idData)}>
                    <img style={{ height: "30px", width: "auto" }} src="/images/select.svg" alt="select" />
                </button>
            </td>
        }

        if (current_selection.idDossierClient === 0) return <></>


        baseItems = (settled_or_deleted ? ticketsFiltered : tickets_supprFiltered)

        if (modeActuel !== modeStat.tickets) return <></>

        const table_titles = [
            "id",
            "date",
            t("activity.amount"),
            "data",
            "options"
        ]

        return <>
            <TicketRows content={tk_rows} />
            <PaginatedItems itemsPerPage={7} items={baseItems} setCurrentItems={setCurrentItems} />

            <table className="table table-hover table-striped">
                <thead>
                    <tr>
                        {table_titles.map((title_elt, i) => (
                            <th key={i} scope="col">{title_elt}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={table_titles.length} >

                            <NbItems count={currentItems.length} total={baseItems.length} />
                        </td>
                    </tr>
                    {currentItems.map((tk_elt) => {
                        return <tr key={tk_elt.IdTicket} id={"tk" + tk_elt.IdTicket}>
                            <th scope="row">{tk_elt.IdTicket}</th>
                            <td>{format_date(Date.parse(tk_elt.DateCreation))}</td>
                            <td><div className="col-8 text-end text-nowrap">{digitGroup(tk_elt.Total)}</div></td>
                            <td>{tk_elt.idDataSource}</td>

                            <ListeOptions id={tk_elt.IdTicket} idData={tk_elt.idDataSource} />
                        </tr>
                    })}
                </tbody>
            </table>
        </>
    }

    // Bouton Afficher le filtre
    const BtnShowFilter = function () {

        return <div className="text-center"><button type="button" className="col-5 btn btn-lg btn-outline-info my-3 fs-3 mx-auto" onClick={() => {
            setShowFilter(!showFilter)
        }}>
            {t("activity.showfilter")}
        </button>
        </div>
    }

    const GetTotal = function (field_name, data, total_or_mean) {
        let total = 0;

        for (let i = 0; i < data.length; i++) {
            total += parseInt(data[i][field_name]);
        }

        if (!total_or_mean) {
            if (data.length === 0) return 0
            return digitGroup(total / data.length)
        }

        return digitGroup(total);
    }

    return <div>
        <Header user={props.user} />

        <div className="row">
            {(((new Date(props.user.dateFin)) < Date.now()) || (props.user.dateFin === undefined)) ?
                <UserAccessExpired user={props.user} />
                :
                <div className="col-xl-6 mx-auto px-3 my-3" style={{ maxWidth: "1200px" }}>

                    <h2 className="mt-6">{t("activity.title")}</h2>

                    {/* ================= */}

                    {/*     CHOICE       */}

                    {/* ================= */}

                    <div className="row">

                        <div className="btn-group mt-3 mb-3 col-lg-8 col-md-10 col-sm-12 mx-auto" role="group" aria-label="Navigation buttons">
                            <button
                                type="button"
                                className={"btn btn-lg fs-4 btn-" + (modeActuel === modeStat.ventes ? "primary" : "outline-dark")}
                                onClick={() => handleChoice(modeStat.ventes)}>
                                {t("activity.onglet1")}
                            </button>

                            {(userHasPriv(props.user, liste_priv.dossier.stats_benef, current_selection.idDossierClient, 0) && (categorie === 1)) &&
                                <button
                                    type="button"
                                    className={"btn btn-lg fs-4 btn-" + (modeActuel === modeStat.benef ? "primary" : "outline-dark")}
                                    onClick={() => handleChoice(modeStat.benef)}>
                                    {t("activity.onglet2")}
                                </button>
                            }

                            <button
                                type="button"
                                className={"btn btn-lg fs-4 btn-" + (modeActuel === modeStat.tickets ? "primary" : "outline-dark")}
                                onClick={() => handleChoice(modeStat.tickets)}>
                                TICKETS
                            </button>

                        </div>
                    </div>

                    {/* ================= */}

                    {/* VENTES - BENEFICE */}

                    {/* ================= */}



                    {/* FILTRE VENTE/BENEF */}

                    {showFilter &&
                        <ActivityFilter
                            current_selection={current_selection}
                            setCurrent_Selection={setCurrent_Selection}
                            modeActuel={modeActuel}
                            user={props.user}
                            setShowFilter={setShowFilter}
                            fetchDataFromServer={fetchDataFromServer}
                        />}

                    <MySpinner show={showSpinner} myText={t("general.loading") + " Data"} />

                    {((modeActuel === modeStat.ventes) || (modeActuel === modeStat.benef)) &&
                        <>

                            {!showFilter && <div className="col-12">

                                {/* BOUTONS GERANT L'AFFICHAGE DU GRAPHIQUE */}

                                {!showSpinner &&

                                    <div className="row row-cols-auto mb-3">
                                        <div className="btn-group m-3 col mx-auto" role="group" aria-label="Filter buttons">
                                            <button type="button" className={"fs-4 btn btn-lg btn-" + (categorie === 1 ? "secondary" : "outline-dark")} onClick={() => { setCategory(1) }}>{slang("Globales", "Global")}</button>
                                            <button type="button" className={"fs-4 btn btn-lg btn-" + (categorie === 2 ? "secondary" : "outline-dark")} onClick={() => {
                                                setCategory(2)
                                                setModeActuel(1)
                                            }}>{slang("Groupes_Fam", "Fam_Groups")}</button>
                                            <button type="button" className={"fs-4 btn btn-lg btn-" + (categorie === 3 ? "secondary" : "outline-dark")} onClick={() => {
                                                setCategory(3)
                                                setModeActuel(1)
                                            }}>{slang("Activités", "Activities")}</button>
                                        </div>

                                        <div className="btn-group m-3 col mx-auto" role="group" aria-label="Filter buttons">
                                            <button type="button" className={"fs-4 btn btn-lg btn-" + (groupeDates === 1 ? "secondary" : "outline-dark")} onClick={() => setGroupeDates(1)}>{slang("Jours", "Days")}</button>
                                            <button type="button" className={"fs-4 btn btn-lg btn-" + (groupeDates === 2 ? "secondary" : "outline-dark")} onClick={() => setGroupeDates(2)}>{slang("Semaines", "Weeks")}</button>
                                            <button type="button" className={"fs-4 btn btn-lg btn-" + (groupeDates === 3 ? "secondary" : "outline-dark")} onClick={() => setGroupeDates(3)}>{slang("Mois", "Months")}</button>
                                        </div>

                                        {categorie > 1 &&
                                            <div className="btn-group m-3 col mx-auto" role="group" aria-label="Filter buttons">
                                                <button type="button" className={"fs-4 btn btn-lg btn-" + (typeGraph === 1 ? "secondary" : "outline-dark")} onClick={() => setTypeGraph(1)}>{slang("Courbe", "Line chart")}</button>
                                                <button type="button" className={"fs-4 btn btn-lg btn-" + (typeGraph === 2 ? "secondary" : "outline-dark")} onClick={() => setTypeGraph(2)}>{slang("Camembert", "Pie Chart")}</button>
                                            </div>
                                        }
                                    </div>
                                }

                                {/* GRAPHIQUES */}

                                {!showSpinner && <div className="row vh-75">

                                    {(categorie === 1) &&
                                        <div className="mx-auto col-10 col-md-12">

                                            < Line
                                                data={{
                                                    labels: data_labels1,
                                                    datasets: datasets1,
                                                }}
                                            />
                                        </div>
                                    }

                                    {(categorie > 1) &&
                                        <>
                                            {(typeGraph === 1) &&

                                                <div className="mx-auto col-10 col-md-12">
                                                    < Line
                                                        data={{
                                                            datasets: datasets2,
                                                        }}
                                                    />
                                                </div>
                                            }

                                            {(typeGraph === 2) &&
                                                <div className="mx-auto col-7">

                                                    < Pie
                                                        data={{
                                                            labels: data_labels3,
                                                            datasets: datasets3,
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </>
                                    }

                                </div>
                                }
                            </div>}
                        </>
                    }

                    {/* ================= */}

                    {/* TICKETS + TICKETS SUPPRIMES */}

                    {/* ================= */}

                    {((modeActuel === modeStat.tickets) && !showSpinner) && <>

                        {!showFilter && <p className="display-3 mt-3">Tickets</p>}

                        <div className="col-11 mx-auto">

                            {!showFilter &&
                                <div className="row">

                                    <div id="formDisplayTicket"></div>

                                    <div className="col-lg-6 col-md-9 col-sm-12">

                                        <MyInputSearch
                                            myLabel="Tickets"
                                            idGroupe={100}
                                            items={tickets}
                                            setItemsFiltered={setTicketsFiltered}
                                            filt1="IdTicket"
                                            filt2="Total"
                                        />

                                    </div>

                                    <ListeTickets settled_or_deleted={true} id_display="formDisplayTicket" />

                                </div>
                            }

                        </div>

                        {!showFilter && <p className="display-3 mt-3 pt-3">{t("activity.onglet4")}</p>}

                        <div className="col-11 mx-auto">

                            {!showFilter &&
                                <div className="row">

                                    <div id="formDisplayTicketSuppr"></div>

                                    <div className="col-md-5 col-sm-12">

                                        <div className="input-group mb-1 fs-4">
                                            <MyInputSearch
                                                myLabel={t("activity.onglet4")}
                                                idGroupe={101}
                                                items={tickets_suppr}
                                                setItemsFiltered={setTickets_supprFiltered}
                                                filt1="IdTicket"
                                                filt2="Total"
                                            />
                                        </div>

                                    </div>

                                    <ListeTickets settled_or_deleted={false} id_display="formDisplayTicketSuppr" />

                                </div>
                            }

                        </div>
                    </>
                    }



                </div>
            }

            {/* TABLEAUX */}
            <div className="col-xl-6">

                {!showFilter && <BtnShowFilter />}

                <p className="fs-4 text-center text-primary mt-1">{JSON.stringify({
                    [slang("Début", "Start")]: current_selection.dateDebut,
                    [slang("Fin", "End")]: current_selection.dateFin,
                    doss: current_selection.idDossierClient,
                    data: current_selection.idData,
                }, null, " ").replace(/"/g, '')}</p>

                {(((modeActuel === modeStat.ventes) || (modeActuel === modeStat.benef)) && (!showSpinner) && (!showFilter)) && <>

                    {/* TABLEAUX VENTES GLOBALES */}

                    {((categorie === 1) && (modeActuel !== modeStat.benef)) &&
                        <div className="mx-2" style={{ marginTop: "50px" }}>

                            <ResponsiveTable
                                itemsPerPage={10}
                                listOfItems={typeGraph === 2 ? dataTable3 : dataTable1}
                                table_titles={[slang("Ventes", "Sales"), slang("Montant", "Amount")]}
                                Row={({ elt }) => {

                                    return <tr
                                        key={elt.id}
                                        onClick={() => { }}
                                    >
                                        <>
                                            <td className="text-center">{elt.label}</td>
                                            <td className="text-center">{digitGroup(elt.value)}</td>
                                        </>
                                    </tr>
                                }}
                                LastRow={({ data }) => {

                                    return <>
                                        <tr className="fw-bold fs-2">
                                            <td className="text-center" colSpan={1}>{slang("Moyenne", "Mean")}</td>
                                            <td className="text-center">{GetTotal("value", data, false)}</td>
                                        </tr>
                                        <tr className="fw-bold fs-2">
                                            <td className="text-center" colSpan={1}>Grand Total</td>
                                            <td className="text-center">{GetTotal("value", data, true)}</td>
                                        </tr>
                                    </>
                                }
                                }
                            />
                        </div>
                    }

                    {/* TABLEAU FAM_GPE / ACTIVITES */}

                    {((categorie !== 1) && (modeActuel === modeStat.ventes)) &&
                        <div className="mx-2" style={{ marginTop: "50px" }}>

                            <ResponsiveTable
                                itemsPerPage={10}
                                listOfItems={dataTable2}
                                table_titles={data_labels2}
                                Row={({ elt }) => {

                                    // table_titles={["data","value"]}
                                    // console.log("datasets1",datasets1);
                                    // console.log("datasets2",datasets2);
                                    // console.log("datasets3",datasets3);


                                    return <tr
                                        key={elt.id}
                                        onClick={() => { }}
                                    >
                                        <>
                                            {data_labels2.map((l, idx) => {

                                                if (idx === 0) return <td key={"td_" + idx} className="text-center">{elt[idx]}</td>

                                                return <td key={"td_" + idx} className="text-center">{digitGroup(elt[idx])}</td>
                                            })
                                            }
                                        </>
                                    </tr>
                                }}
                                LastRow={({ data }) => {

                                    return <>
                                        <tr className="fw-bold fs-2">

                                            {data_labels2.map((l, idx) => {

                                                if (idx === 0) return <td key={"lr_-1"} className="text-center" colSpan={1}>{slang("Moyenne", "Mean")}</td>

                                                return <td key={"lr_" + idx} className="text-center">{GetTotal(idx, data, false)}</td>
                                            })
                                            }
                                        </tr>
                                        <tr className="fw-bold fs-2">

                                            {data_labels2.map((l, idx) => {

                                                if (idx === 0) return <td key={"lr_-1"} className="text-center" colSpan={1}>Grand Total</td>

                                                return <td key={"lr_" + idx} className="text-center">{GetTotal(idx, data, true)}</td>
                                            })
                                            }
                                        </tr>
                                    </>
                                }
                                }
                            />
                        </div>
                    }
                    {/* TABLEAU BENEFICE */}

                    {((categorie === 1) && (modeActuel === modeStat.benef)) &&
                        <div className="mx-2" style={{ marginTop: "50px" }}>

                            <ResponsiveTable
                                itemsPerPage={10}
                                listOfItems={dataTable1}
                                table_titles={["dates", slang("Ventes", "Sales"), slang("Coût d'achat", "Cost Price"), slang("Bénéfice", "Balance")]}
                                Row={({ elt }) => {


                                    return <tr
                                        key={elt.id}
                                        onClick={() => { }}
                                    >
                                        <>

                                            <td key={"td_0"} className="text-center">{elt[0]}</td>
                                            <td key={"td_1"} className="text-center">{digitGroup(elt[1])}</td>
                                            <td key={"td_2"} className="text-center">{digitGroup(-1 * elt[2])}</td>
                                            <td key={"td_3"} className="text-center">{digitGroup(elt[3])}</td>
                                        </>
                                    </tr>
                                }}
                                LastRow={({ data }) => {



                                    return <>
                                        <tr className="fw-bold fs-2">

                                            {[0, 1, 2, 3].map((l, idx) => {

                                                if (idx === 0) return <td key={"lr_-1"} className="text-center" colSpan={1}>{slang("Moyenne", "Mean")}</td>

                                                return <td key={"lr_" + idx} className="text-center">{(idx === 2 ? "-" : "") + GetTotal(idx, data, false)}</td>
                                            })
                                            }
                                        </tr>
                                        <tr className="fw-bold fs-2">

                                            {[0, 1, 2, 3].map((l, idx) => {

                                                if (idx === 0) return <td key={"lr_-1"} className="text-center" colSpan={1}>Grand Total</td>

                                                return <td key={"lr_" + idx} className="text-center">{(idx === 2 ? "-" : "") + GetTotal(idx, data, true)}</td>
                                            })
                                            }
                                        </tr>
                                    </>
                                }
                                }
                            />
                        </div>
                    }
                </>
                }
            </div>
        </div>

        {((modeActuel === modeStat.tickets || modeActuel === modeStat.tickets_suppr) && !showFilter) &&
            <MyScrollButton />
        }

    </div >
}

export default ActivityPage;